/* Light Mode */
.theme-light
  .p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr.p-row-odd {
  background: #d4fcdc;
}

.theme-light .p-datatable-resizable-table > .p-datatable-thead > tr > th,
.theme-light .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  background: #d4fcf0;
}

.theme-light .text-color {
  color: slategray;
}

.theme-light {
  background-color: #ffffff;
  /* color: #333333; */
}

/* Dark Mode */
.theme-dark {
  background-color: #333333;
  color: #ffffff;
}

.theme-dark h2 {
  color: #ffffff;
}

/* Example styling for PrimeReact DataTable */
/* .theme-light .p-datatable {
  background-color: #ffffff;
  color: #333333;
} */

.theme-dark .p-datatable {
  background-color: #333333;
  color: #ffffff;
}

.theme-dark .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd
/* .p-datatable-resizable-table > .p-datatable-thead > tr > th,
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td  */ {
  background: #133647;
  color: #ffffff;
}

/* .theme-dark .p-datatable .p-datatable-tbody > tr {
  background: #1f2937;
  color: #ffffff;
} */
.theme-dark .p-datatable .p-datatable-tbody > tr,
.theme-dark .p-datatable-resizable-table > .p-datatable-thead > tr > th,
.theme-dark .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  background: #1f2937;
  color: #ffffff;
}

.theme-dark .p-button,
.theme-dark .p-column-filter-menu-button {
  background-color: white;
  border-color: white;
}

.theme-dark [class*="p-paginator"] {
  background: #1f2937;
  color: white;
}

.theme-dark .p-sortable-column-icon {
  color: white;
}

.theme-dark .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.theme-dark .p-column-filter-menu-button.p-column-filter-menu-button-active {
  color:lightsalmon;
}


.height90{
  height: 90vh;
}