/* To compensate for the barchart on Summary page, set the header row as z-index 2 and frozen column as z-index 1   */
.p-datatable-scrollable-table > .p-datatable-thead {
  z-index: 2 !important
}
.p-frozen-column {
  z-index: 1;
}
/* ------------------------- */

.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.d-flex{
  display: flex;
}

.align-item-center{
  align-items: center;
}

/* Adjust z-index for content to appear beneath headers */
/* .p-datatable .p-datatable-tbody {
  position: relative;
  z-index: 0;
} */

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  text-align: left !important;
}

.data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.height90{
  height: 90vh;
}