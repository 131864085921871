.data-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
  }

  .d-flex{
    display: flex;
  }
  
  .align-item-center{
    align-items: center;
  }  .d-flex{
    display: flex;
  }
  
  .align-item-center{
    align-items: center;
  }