/* .nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.nav-bar{
  border-bottom: 1px gray solid;
}

.theme-light .nav-bar{
  border-bottom: 1px lightgray solid;
}


.text-decoration {
  text-decoration: none;

}
.p-menubar {
  width: 100%;
  padding: 0;
  border: 0;
}

.theme-light .p-menubar {
  background-color: #ffffff;
  color: slategray;
}

.theme-dark .p-menubar {
  background-color: #333;
  color: #fff;
}

.theme-dark
  .p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link
  .p-menuitem-text,
.theme-dark
  .p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link
  .p-menuitem-icon {
  color: #fff;
}

.theme-dark .p-menubar .p-menubar-root-list > .p-menuitem a:hover {
  background-color: gray;
  transition: box-shadow 0.2s;
  border-radius: 6px;
}

.p-menubar-start,
.p-menubar-end {
  padding-right: 1rem;
}

.p-button{
  margin: 0.1rem;
  border:1px lightgray solid;
}

.stock-tracker{
  padding-left:0.5rem ;
  display: flex;
  align-items: center;

}

.menuitem-path-current .p-menuitem-text,
.menuitem-path-current .p-menuitem-icon{
  color:#3B82F6 !important
}

.theme-dark .menuitem-path-current:hover .p-menuitem-text,
.theme-dark .menuitem-path-current:hover .p-menuitem-icon{
  color:#fff !important
}

.theme-light .menuitem-path-current:hover .p-menuitem-text,
.theme-light .menuitem-path-current:hover .p-menuitem-icon{
  color:slategray !important
}