.login-background {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(1px);
  z-index: -100;
  position: absolute;
}

.login-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column;
  height: 100vh;
}

.login-content h1 {
  font-size: 3.5rem;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}
